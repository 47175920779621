import Col from "react-bootstrap/Col"
import Container from "react-bootstrap/Container"
import Img from "gatsby-image"
import Layout from "components/layout"
import LocalizedLink from "components/localized-link"
import PropTypes from "prop-types"
import React from "react"
import Row from "react-bootstrap/Row"
import SEO from "components/seo"
import { graphql } from "gatsby"
import styles from "./index.module.scss"
import useTranslations from "components/use-translations"

function ProgramBox({
  buttonColor,
  buttonHref,
  textHref,
  iconImg,
  header,
  shortDescription,
  text,
  buttonText,
}) {
  const link = buttonHref.startsWith("http") ? (
    <a
      href={buttonHref}
      target="_blank"
      rel="noreferrer noopener"
      className={`btn btn-${buttonColor}`}
      role="button"
    >
      {buttonText}
    </a>
  ) : (
    <LocalizedLink
      to={buttonHref}
      className={`btn btn-${buttonColor}`}
      role="button"
    >
      {buttonText}
    </LocalizedLink>
  )
  return (
    <Col className="p-4">
      <div className="border rounded d-flex align-items-center flex-column bg-light py-3">
        <LocalizedLink to={textHref}>
          <Img className="mb-3" fixed={iconImg}></Img>
        </LocalizedLink>
        <LocalizedLink to={textHref} className="text-decoration-none">
          <strong className="text-dark mb-1" style={{ fontSize: 20 }}>
            {header}
          </strong>
        </LocalizedLink>
        <span className="text-muted mb-4" style={{ fontSize: 14 }}>
          {shortDescription}
        </span>
        <span className="text-dark mb-3" style={{ fontSize: 14 }}>
          {text}
        </span>
        {link}
      </div>
    </Col>
  )
}
ProgramBox.propTypes = {
  buttonColor: PropTypes.string,
  buttonHref: PropTypes.string,
  textHref: PropTypes.string,
  iconImg: PropTypes.object,
  header: PropTypes.string,
  shortDescription: PropTypes.string,
  text: PropTypes.string,
  buttonText: PropTypes.string,
  lg: PropTypes.number,
}
export default function Programlar({ data }) {
  const {
    genel: {
      cekilisSitesi,
      tusunamiKutuphaneTakipProgrami,
      servishaneTeknikServisProgrami,
      akilliTahtaKilit,
      tusunamiCekilisProgrami,
      kutuphaneOtomasyonu,
      hizliCekilisSitesi,
      siteyeGit,
      hemenIndir,
      aklliKilit,
      cekilisOtomasyonu,
    },
    programlar: {
      seoTitle,
      seoDescription,
      kurumsal,
      cekilisSistemleri,
      aracGerec,
      geriSayimAraclari,
      kutuphaneAciklama,
      akilliKilitAciklama,
      durduruldu,
      cekilisSiteAciklama,
      cekilisProgramAciklama,
      qrKodOlusturucu,
      kareKodOlusturucu,
      qrKodAciklama,
      kimlikDogrulayici,
      tcKimlikNoDogrulayici,
      kimlikDogrulayiciAciklama,
      ygsGeriSayim,
      sinavGeriSayimAraci,
      ygsGeriSayimAciklama,
      okulSayar,
      okulGeriSayimAraci,
      okulSayarAciklama,
    },
  } = useTranslations()
  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        pathname="https://www.kodvizit.com/programlar"
        datePublished="2017-02-07T16:17:13+00:00"
        dateModified="2020-07-17T16:53:13+00:00"
      />
      <Container
        className={`text-center ${styles.programlar}`}
        style={{ paddingBottom: 80, minHeight: 500 }}
      >
        <h1>{kurumsal} </h1>
        <Row className="d-block d-lg-flex">
          <ProgramBox
            iconImg={data.tktIcon.childImageSharp.fixed}
            header={tusunamiKutuphaneTakipProgrami}
            textHref="/kutuphane-programi/"
            shortDescription={kutuphaneOtomasyonu}
            text={kutuphaneAciklama}
            buttonColor="success"
            buttonText={hemenIndir}
            buttonHref="/kutuphane-programi/indir/"
          />
          <ProgramBox
            iconImg={data.akilliKilitIcon.childImageSharp.fixed}
            header={aklliKilit}
            textHref="/akilli-tahta-kilit/"
            shortDescription={akilliTahtaKilit}
            text={akilliKilitAciklama}
            buttonColor="secondary"
            buttonText={durduruldu}
            buttonHref="/akilli-tahta-kilit"
          />
          <ProgramBox
            iconImg={data.qrIcon.childImageSharp.fixed}
            header={servishaneTeknikServisProgrami}
            textHref="/servishane/"
            shortDescription={servishaneTeknikServisProgrami}
            text={servishaneTeknikServisProgrami}
            buttonColor="primary"
            buttonText={siteyeGit}
            buttonHref="http://www.servishane.com/crm"
          />
        </Row>
        <h1>{cekilisSistemleri}</h1>
        <Row className="d-block d-lg-flex">
          <ProgramBox
            iconImg={data.cekilisSiteIcon.childImageSharp.fixed}
            header={cekilisSitesi}
            textHref="/cekilis-sitesi/"
            shortDescription={hizliCekilisSitesi}
            text={cekilisSiteAciklama}
            buttonColor="primary"
            buttonText={siteyeGit}
            buttonHref="/cekilis"
          />
          <ProgramBox
            iconImg={data.cekilisProgramIcon.childImageSharp.fixed}
            header={tusunamiCekilisProgrami}
            textHref="/cekilis-programi/"
            shortDescription={cekilisOtomasyonu}
            text={cekilisProgramAciklama}
            buttonColor="success"
            buttonText={hemenIndir}
            buttonHref="/cekilis-programi/indir/"
          />
        </Row>

        <h1>{aracGerec}</h1>
        <Row className="d-block d-lg-flex">
          <ProgramBox
            iconImg={data.qrIcon.childImageSharp.fixed}
            header={qrKodOlusturucu}
            textHref="#"
            shortDescription={kareKodOlusturucu}
            text={qrKodAciklama}
            buttonColor="secondary"
            buttonText={durduruldu}
            buttonHref="#"
          />
          <ProgramBox
            iconImg={data.kimlikIcon.childImageSharp.fixed}
            header={kimlikDogrulayici}
            textHref="#"
            shortDescription={tcKimlikNoDogrulayici}
            text={kimlikDogrulayiciAciklama}
            buttonColor="secondary"
            buttonText={durduruldu}
            buttonHref="#"
          />
        </Row>

        <h1>{geriSayimAraclari}</h1>
        <Row className="d-block d-lg-flex">
          <ProgramBox
            iconImg={data.geriSayimIcon.childImageSharp.fixed}
            header={ygsGeriSayim}
            textHref="#"
            shortDescription={sinavGeriSayimAraci}
            text={ygsGeriSayimAciklama}
            buttonColor="secondary"
            buttonText={durduruldu}
            buttonHref="#"
          />
          <ProgramBox
            iconImg={data.okulSayarIcon.childImageSharp.fixed}
            header={okulSayar}
            textHref="#"
            shortDescription={okulGeriSayimAraci}
            text={okulSayarAciklama}
            buttonColor="secondary"
            buttonText={durduruldu}
            buttonHref="#"
          />
        </Row>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query {
    tktIcon: file(relativePath: { eq: "icon/tkticon48.png" }) {
      childImageSharp {
        fixed(width: 48, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    qrIcon: file(relativePath: { eq: "icon/qr48.png" }) {
      childImageSharp {
        fixed(width: 48, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    kimlikIcon: file(relativePath: { eq: "icon/kimlik48.png" }) {
      childImageSharp {
        fixed(width: 48, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cekilisSiteIcon: file(relativePath: { eq: "icon/cekilissite48.png" }) {
      childImageSharp {
        fixed(width: 48, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    cekilisProgramIcon: file(
      relativePath: { eq: "icon/cekilisprogram48.png" }
    ) {
      childImageSharp {
        fixed(width: 48, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    geriSayimIcon: file(relativePath: { eq: "icon/gerisayim48.png" }) {
      childImageSharp {
        fixed(width: 48, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    akilliKilitIcon: file(relativePath: { eq: "icon/akillikilit128.png" }) {
      childImageSharp {
        fixed(width: 48, height: 48, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
    okulSayarIcon: file(relativePath: { eq: "icon/okulsayar48.png" }) {
      childImageSharp {
        fixed(width: 48, quality: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  }
`

Programlar.propTypes = {
  data: PropTypes.object,
}
